* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
  cursor: pointer;

  img {
    vertical-align: top;
  }
}

img {
  border: none;
}

ul,
li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  // text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family:BBH;
  src:url(../../assets/font/Baloo_Bhaina/BalooBhaina2-ExtraBold.ttf)
}
@font-face {
  font-family:BBM;
  src:url(../../assets/font/Baloo_Bhaina/BalooBhaina2-SemiBold.ttf)
}
@font-face {
  font-family:Avenir;
  src: url(../../assets/font/Avenir/Avenir-Light-07.ttf);
}


@font-face {
  font-family:AVM;
  src: url(../../assets/font/Avenir/Avenir-Medium-09.ttf);
}

@font-face {
  font-family:AVH;
  src: url(../../assets/font/Avenir/Avenir-Heavy-05.ttf);
}
@font-face {
  font-family:AVHL;
  src: url(../../assets/font/Avenir/Avenir-HeavyOblique-06.ttf);
}

@font-face {
  font-family:AVB;
  src: url(../../assets/font/Avenir/Avenir-Black-03.ttf);
}



.font-BBH{
  font-family:BBH;
}

.font-BBM{
  font-family:BBM;
}

.font-AVM{
  font-family:AVM;
}

.font-AVB{
  font-family:AVB;
}

.font-AVH{
  font-family:AVH;
}

.font-AVHL{
  font-family:AVHL;
}

.text-uppercase{
  text-transform: uppercase;
}

.bg-black {
  --tw-bg-opacity:1;
  background-color:rgb(0 0 0/var(--tw-bg-opacity))
}

.bg-blue {
  background-color:#2B93FF;
}

.bg-gold {
  background-color:#FFC75C;
}

.font-normal{
  font-style: normal;
}

.block {
  display:block
}
.inline-block {
  display:inline-block
}
.inline {
  display:inline
}
.flex {
  display:flex
}
.inline-flex {
  display:inline-flex
}
.table {
  display:table
}
.grid {
  display:grid
}
.hidden {
  display:none
}

.flex-row {
  flex-direction:row
}
.flex-col {
  flex-direction:column
}
.flex-wrap {
  flex-wrap:wrap
}
.flex-nowrap {
  flex-wrap:nowrap
}
.content-center {
  align-content:center
}
.items-end {
  align-items:flex-end
}
.items-center {
  align-items:center
}
.justify-start {
  justify-content:flex-start
}
.justify-end {
  justify-content:flex-end
}
.justify-center {
  justify-content:center
}
.justify-between {
  justify-content:space-between
}
.justify-evenly {
  justify-content:space-evenly
}

.text-blue {
  color: #2DA3FF;
}

.text-gold{
  color: #FFBE4E;
}

.text-sm {
  font-size:.24rem;
  line-height:1.2
}
.text-xl {
  font-size:.28rem;
  line-height:1.2
}
.text-2xl {
  font-size:.34rem;
  line-height:1.2
}

.text-head {
  font-size:.46rem;
  line-height:1.2
}

.text-3xl {
  font-size:.70rem;
  line-height:1.2
}
.text-4xl {
  font-size:.80rem;
  line-height:1.2
}
.h-full {
  height:100%
}
.w-full {
  width:100%
}
.w-70{
  width: .7rem;
}

.w-90{
  width: .9rem;
}

.lh-70{
  line-height: .7rem;
}

.top-0 {
  top:.0
}

.top-02 {
  top:.02rem
}

.top-1 {
  top:.05rem
}
.ml-0{
  margin-left: 0;
}
.ml-1{
  margin-left: .15rem;
}
.mx-1{
  margin: 0 .15rem;
}

.mt-5 {
  margin-top:1.25rem
}

.mr-1 {
  margin-right:.25rem
}
.mr-2 {
  margin-right:.5rem
}

.m-auto {
  margin:auto
}
.mb-1{
  margin-bottom: 0.15rem;
}

.mt-1{
  margin-top: 0.15rem;
}

.p-3 {
  padding:.75rem
}
.p-0 {
  padding:0
}
.p-2 {
  padding:.5rem
}
.p-\[1px\] {
  padding:1px
}
.p-1 {
  padding:.25rem
}
.p-4 {
  padding:1rem
}
.\!p-1 {
  padding:.25rem!important
}
.p-\[3px\] {
  padding:3px
}
.p-\[5px\] {
  padding:5px
}
.p-5 {
  padding:1.25rem
}
.px-4 {
  padding-left:1rem;
  padding-right:1rem
}
.py-1 {
  padding-bottom:.15rem;
  padding-top:.15rem
}
.py-sm {
  padding-bottom:.3rem;
  padding-top:.3rem
}
.py-3 {
  padding-bottom:.75rem;
  padding-top:.75rem
}
.px-3 {
  padding-left:.75rem;
  padding-right:.75rem
}
.px-5 {
  padding-left:1.25rem;
  padding-right:1.25rem
}
.px-2 {
  padding-left:.5rem;
  padding-right:.5rem
}
.py-4 {
  padding-bottom:1rem;
  padding-top:1rem
}
.py-5 {
  padding-bottom:1.25rem;
  padding-top:1.25rem
}
.\!py-4 {
  padding-bottom:1rem!important;
  padding-top:1rem!important
}
.py-7 {
  padding-bottom:1.75rem;
  padding-top:1.75rem
}
.py-2 {
  padding-bottom:.5rem;
  padding-top:.5rem
}
.px-7 {
  padding-left:1.75rem;
  padding-right:1.75rem
}
.py-8 {
  padding-bottom:2rem;
  padding-top:2rem
}
.px-6 {
  padding-left:1.5rem;
  padding-right:1.5rem
}
.px-8 {
  padding-left:2rem;
  padding-right:2rem
}
.px-10 {
  padding-left:2.5rem;
  padding-right:2.5rem
}
.px-15 {
  padding-left:3.5rem;
  padding-right:3.5rem
}
.\!px-6 {
  padding-left:1.5rem!important;
  padding-right:1.5rem!important
}
.px-1 {
  padding-left:.25rem;
  padding-right:.25rem
}
.py-\[2px\] {
  padding-bottom:2px;
  padding-top:2px
}
.py-6 {
  padding-bottom:1.5rem;
  padding-top:1.5rem
}
.py-0 {
  padding-bottom:0;
  padding-top:0
}
.py-10 {
  padding-bottom:2.5rem;
  padding-top:2.5rem
}
.px-0 {
  padding-left:0;
  padding-right:0
}
.pl-3 {
  padding-left:.75rem
}
.pr-0 {
  padding-right:0
}
.pr-4 {
  padding-right:1rem
}
.pt-20 {
  padding-top:5rem
}
.pt-3 {
  padding-top:.75rem
}
.pr-16 {
  padding-right:4rem
}
.pt-12 {
  padding-top:3rem
}
.pb-16 {
  padding-bottom:4rem
}
.pb-2 {
  padding-bottom:.5rem
}
.pr-3 {
  padding-right:.75rem
}
.pb-3 {
  padding-bottom:.75rem
}
.pt-8 {
  padding-top:2rem
}
.pt-2 {
  padding-top:.5rem
}
.pb-1 {
  padding-bottom:.25rem
}
.pt-4 {
  padding-top:1rem
}
.pt-1 {
  padding-top:.25rem
}
.pl-4 {
  padding-left:1rem
}
.pr-1 {
  padding-right:.25rem
}
.pt-0 {
  padding-top:0
}
.pb-5 {
  padding-bottom:1.25rem
}
.pt-10 {
  padding-top:2.5rem
}
.pt-16 {
  padding-top:4rem
}
.pl-2 {
  padding-left:.5rem
}
.pl-6 {
  padding-left:1.5rem
}
.pr-5 {
  padding-right:1.25rem
}
.pr-6 {
  padding-right:1.5rem
}
.pb-8 {
  padding-bottom:2rem
}
.pb-0 {
  padding-bottom:0
}
.pr-2 {
  padding-right:.5rem
}
.pb-10 {
  padding-bottom:2.5rem
}
.pb-4 {
  padding-bottom:1rem
}
.pt-6 {
  padding-top:1.5rem
}
.pb-14 {
  padding-bottom:3.5rem
}
.pt-14 {
  padding-top:3.5rem
}
.pt-32 {
  padding-top:8rem
}
.pb-20 {
  padding-bottom:5rem
}
.pt-5 {
  padding-top:1.25rem
}
.text-left {
  text-align:left
}
.text-center {
  text-align:center
}
.text-right {
  text-align:right
}
.align-middle {
  vertical-align:middle
}
.align-bottom {
  vertical-align:bottom
}
.visible {
  visibility:visible
}
.invisible {
  visibility:hidden
}
.fixed {
  position:fixed
}
.absolute {
  position:absolute
}

.relative {
  position: relative;
}
.sticky {
  position:sticky
}

.text-white{
  color: #fff;
}
.text-white\/80{
  color: rgba(255, 255, 255, 0.8);
}

.\!bg-transparent {
  background-color:transparent !important;
}
.mw-\[330px\]{
  max-width: 330px;
}

.w-\[42px\]{
  width: .42rem;
}

.grid {
  display:grid
}

.grid-cols-1 {
  grid-template-columns:repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns:repeat(2, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns:repeat(2, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns:repeat(4, minmax(0, 1fr))
}
.w-80\%{
  width: 80%;
}
.w-60\%{
  width: 60%;
}
@media (min-width:640px) {
  .sm\:hidden {
    display: none
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:px-2 {
    padding-left: .4rem;
    padding-right: .4rem
}

  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  .sm\:\!px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem
  }
}
@media (min-width:768px) {
  .md\:block {
    display: block
  }
  .md\:flex {
    display: flex
}
  .md\:hidden {
    display: none
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:px-5 {
    padding-left:1.25rem;
    padding-right:1.25rem
  }

  .md\:text-3xl {
    font-size:.70rem;
    line-height:1.2
  }
  .md\:text-left{
    text-align: left;
  }

  .md\:\[width-80\%\]{
    width: 80%;
  }
  .md\:w-full{
    width: 100%;
  }
  .md\:text-sm {
    font-size:.24rem;
    line-height:1.2
  }
  .md\:flex-row{
    flex-direction: row;
  }
  .md\:ml-1{
    margin-left: 0.15rem;
  }

  .md\:ml-0{
    margin-left: 0;
  }
  .md\:mb-0{
    margin-bottom: 0;
  }
  .md\:w-70{
    width: .7rem;
  }
  .md\:text-2xl {
    font-size:.34rem;
    line-height:1.2
  }
  .md\:py-1 {
    padding-bottom:.15rem;
    padding-top:.15rem
  }
  .md\:justify-between{
    justify-content: space-between;
  }
  .md\:py-1 {
    padding-bottom:.15rem;
    padding-top:.15rem
  }
}

@media (min-width:1024px) {
  
  .lg\:.flex-col{
    flex-direction: column;
  }
  .lg\:p-1 {
        padding: .25rem
  }

  .lg\:px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem
  }

  .lg\:py-4 {
      padding-bottom: 1rem;
      padding-top: 1rem
  }

  .lg\:px-2 {
      padding-left: .5rem;
      padding-right: .5rem
  }

  .lg\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem
  }

  .lg\:py-1 {
      padding-bottom: .25rem;
      padding-top: .25rem
  }

  .lg\:px-4 {
      padding-left: 1rem;
      padding-right: 1rem
  }

  .lg\:px-7 {
      padding-left: 1.75rem;
      padding-right: 1.75rem
  }

  .lg\:py-5 {
      padding-bottom: 1.25rem;
      padding-top: 1.25rem
  }

  .lg\:py-0 {
      padding-bottom: 0;
      padding-top: 0
  }

  .lg\:px-3 {
      padding-left: .75rem;
      padding-right: .75rem
  }

  .lg\:py-\[6px\] {
      padding-bottom: 6px;
      padding-top: 6px
  }

  .lg\:px-0 {
      padding-left: 0;
      padding-right: 0
  }

  .lg\:pr-1 {
      padding-right: .25rem
  }

  .lg\:pb-0 {
      padding-bottom: 0
  }

  .lg\:pl-4 {
      padding-left: 1rem
  }

  .lg\:pt-2 {
      padding-top: .5rem
  }

  .lg\:pr-6 {
      padding-right: 1.5rem
  }

  .lg\:pl-1 {
      padding-left: .25rem
  }

  .lg\:pb-5 {
      padding-bottom: 1.25rem
  }

  .lg\:pt-6 {
      padding-top: 1.5rem
  }

  .lg\:pt-0 {
      padding-top: 0
  }

  .lg\:text-left {
      text-align: left
  }

  .lg\:align-middle {
      vertical-align: middle
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }
}