.countDownBox{
    font-size: .38rem;
    line-height: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .item{
        .num{
            width: 1.6rem;
            height: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: .06rem solid #2B93FF;
            border-radius: .15rem;
            font-family: AVHL;
            font-weight: bolder;
            font-size: .6rem;
        }
        .text{
            text-align: center;
            margin-top: .2rem;
            color: #2B93FF;
            text-transform: uppercase;
        }
    }
    .spec{
        font-size: 1.2rem;
        line-height: 1;
        padding: 0 .1rem;
        color: #2B93FF;
    }
}