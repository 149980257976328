iframe {
  display: none;
}


html {
  font-family:Avenir, system-ui, sans-serif;
  line-height:1;
  font-size: 20px;
}

html,body{
  width: 100%;
  height: 100%;
}

body,html{
  overflow-x: hidden;
}



.cta-btn-transparent {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: .2rem .37rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 200px;
  background-image: linear-gradient(84deg, #FFC66A, #3F8BFF);
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
  color: #fff;
  line-height: .4rem;
  font-weight: 700;
  text-decoration: none;
  min-width: 4.2rem;
  max-width: 225px;
  border: none;
  outline: none;
  &:hover{
    .cta-btn-transparent__animbox{
      width:100%;
    }
  }

  @media (min-width:768px) {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 0;
    min-width: 2.8rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 200px;
    background-image: linear-gradient(84deg, #FFC66A, #3F8BFF);
    -webkit-transition: box-shadow 300ms ease;
    transition: box-shadow 300ms ease;
    color: #fff;
    line-height: .26rem;
    font-weight: 700;
    max-height: .7rem;
    text-decoration: none;
    max-width: 330px;
    &:hover{
      .cta-btn-transparent__animbox{
        
        width:100%;
      }
    }
  }
}

.cta-btn--ico {
  position: relative;
  top: -2px;
  z-index: 4;
  width: .46rem;
  margin-right: .2rem;
  img{
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }

  @media (min-width:768px) {
    width: .32rem;
  }
}


.cta-btn-transparent__gradhide {
  position: absolute;
  left: .2%;
  top: 1%;
  right: .2%;
  bottom: 1%;
  z-index: 2;
  margin: 1px;
  border-radius: 200px;
  background-color: #0E2232;
}

.cta-btn__txt {
  position: relative;
  z-index: 4;
  -webkit-transform: translate(0px, 1px);
  -ms-transform: translate(0px, 1px);
  transform: translate(0px, 1px);
  font-size: .36rem;
  top: -.03rem;
  text-align: center;
  @media (min-width:768px) {
   
    font-size: .24rem;
  
  }
}



.cta-btn-transparent__gradhide-red {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  margin: 0px;
  border-radius: 200px;
  background-color: #2B93FF;
  opacity: 0;
}

.cta-btn-transparent__animbox {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 3;
  width: 0%;
  border-radius: 200px;
  background-color: #2B93FF;
  transition: width .5s ease-in-out;
}


.Toastify__toast-body{
  font-size: .2rem;
}