.mintComp{
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(../../assets/mobile/mint_bg.png) no-repeat top center;
  background-size: 100%;
  @media (min-width:768px) {
    background: url(../../assets/img/mint.png) no-repeat top center;
    background-size: cover;

  }
  .header{
    background: none;
    width: 100%;
  }
  .mint{
    color: #fff;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
   
    .container{
      padding-top: 2rem;
      width: 100%;
      
      .minted{
        font-size: .6rem;
        color: #2B93FF;
        font-family: AVB;
        text-align: center;
        margin-bottom: .2rem;
        margin-top: .4rem;
      }
      .count{
        display: flex;
        align-items: center;
        justify-content: center;
        .inputGroup{
          display: flex;
          align-items: stretch;
        justify-content: center;
          button{
            width: 1.2rem;
            height: 1.2rem;
            background-color: #2B93FF;
            border: none;
            outline: none;
            border-radius: .3rem;
            color: #fff;
            font-size: .6rem;
            font-family: AVB;
            font-weight: bolder;
          }
          input{
            width: 4.5rem;
            // height: 100%;
            outline: none;
            border: none;
            background-color: rgba(43, 146, 255, 0.14);
            text-align: center;
            color: #fff;
            margin: 0 .4rem;
            font-family: AVB;
            font-weight: bolder;
            font-size: .6rem;
          }
        }
        
      }
      .from-btn{
        width: 100%;
        text-align: center;
        .mintBtn{
          background-image: linear-gradient(90deg, #FFC66A, #3F8BFF);
          border: none;
          outline: none;
          width: 6rem;
          margin: auto;
          color: #000;
          height: 1.26rem;
          font-size: .54rem;
          font-family: AVB;
          font-weight: bolder;
          margin-top: 1rem;
          border-radius: 1rem;
        }
      }
      
  
    }
  
    .disconnect{
      position: absolute;
      bottom: -.05rem;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 100%);
      font-size: .48rem;
      padding: .3rem .4rem;
      background-color: #0F304B;
      border-radius: .2rem;
      cursor: pointer;
    }
  
    @media (min-width:768px) {
      .disconnect{
        position: absolute;
        bottom: -.05rem;
        left: 50%;
        min-width: 100%;
        transform: translate(-50%, 100%);
        font-size: .32rem;
        padding: .2rem .4rem;
        background-color: #0F304B;
        border-radius: .2rem;
      }
    
      .container{
        padding-top: 0rem;
        background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
        -webkit-backdrop-filter: blur(10px );
        backdrop-filter: blur(10px);
        padding-bottom: .5rem;
        .nftBox{
          width: 7rem;
          margin: auto;
        }
        .minted{
          font-size: .4rem;
          margin-bottom: .2rem;
          margin-top: .1rem;
        }
        .count{
          .inputGroup{
            button{
              width: .8rem;
              height: .8rem;
              background-color: #2B93FF;
              border: none;
              outline: none;
              border-radius: .16rem;
              color: #fff;
              font-size: .3rem;
            }
            input{
              width: 4.5rem;
              // height: 100%;
              outline: none;
              border: none;
              background-color: rgba(43, 146, 255, 0.14);
              text-align: center;
              color: #fff;
              font-size: .24rem;
              margin: 0 .4rem;
            }
          }
          
        }
        .from-btn{
          width: 100%;
          text-align: center;
          .mintBtn{
            background-image: linear-gradient(90deg, #FFC66A, #3F8BFF);
            border: none;
            outline: none;
            width: 4rem;
            margin: auto;
            color: #000;
            height: .8rem;
            font-size: .32rem;
            font-family: AVB;
            font-weight: bolder;
            margin-top: .5rem;
            border-radius: 1rem;
          }
        }
        
    
      }
    }
  }
}



.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border {
  display: inline-block;
  width: .3rem;
  height: .3rem;
  border: 0.28em solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}