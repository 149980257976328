.gamefiComp{
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(../../assets/mobile/mint_bg.png) no-repeat top center;
  background-size: 100%;
  @media (min-width:768px) {
    background: url(../../assets/img/mint.png) no-repeat top center;
    background-size: cover;

  }
  .header{
    background: none;
    width: 100%;
  }
  .mint{
    color: #fff;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
   
    .container{
      padding-top: 2rem;
      width: 100%;
      
      h1{
        font-size: 20px;
        text-align: center;
      }
    
  
    }
  
    .disconnect{
      position: absolute;
      bottom: -.05rem;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 100%);
      font-size: .48rem;
      padding: .3rem .4rem;
      background-color: #0F304B;
      border-radius: .2rem;
      cursor: pointer;
    }
  
    @media (min-width:768px) {
      .disconnect{
        position: absolute;
        bottom: -.05rem;
        left: 50%;
        min-width: 100%;
        transform: translate(-50%, 100%);
        font-size: .32rem;
        padding: .2rem .4rem;
        background-color: #0F304B;
        border-radius: .2rem;
      }
    
      .container{
        padding-top: 0rem;
        padding-bottom: .5rem;
        .nftBox{
          width: 7rem;
          margin: auto;
        }
       
    
      }
    }
  }
}



.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border {
  display: inline-block;
  width: .3rem;
  height: .3rem;
  border: 0.28em solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}