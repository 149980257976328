.premint{
  color: #fff;
  min-height: 100vh;
  background: url(../../assets/mobile/mint_bg.png) no-repeat top center;
  background-size: 100%;
  @media (min-width:768px) {
    background: url(../../assets/img/mint.png) no-repeat top center;
    background-size: cover;

  }
  .header{
    background: none;
  }
  .container{
    padding-top: 3rem;
    .title{
      font-size: .9rem;
      font-family: BBM;
      margin-top: 1.2rem;
      text-align: center;
    }
    .des{
      font-size: .6rem;
      text-align: center;
      margin-bottom: 2.4rem;
    }
  }

  @media (min-width:768px) {
    display: flex;
    flex-direction: column;
    .container{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -0.8rem;
      padding-top: 0rem;
      background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
      -webkit-backdrop-filter: blur(10px );
      backdrop-filter: blur(10px);
      padding-bottom: .5rem;
      .title{
        font-size: .6rem;
        font-family: BBM;
        margin-top: 1.2rem;
        text-align: center;
      }
      .des{
        font-size: .3rem;
        margin-top: .2rem;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
}